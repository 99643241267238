import "./NavBar.sass";
import { useState } from "react";
import { NavLink } from "react-router-dom";

function NavBar() {
  const [navToggle, setNavToggle] = useState(false);

  const toggleNav = () => {
    setNavToggle(!navToggle);
  };

  return (
    <div className="nav-wrapper">
      <nav>
        <div className="logo">
          <NavLink to="/" exact>
            Brennan
          </NavLink>
        </div>
        <ul className={navToggle === false ? "" : "nav-active"}>
          <li>
            <NavLink to="/" exact>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/projects" exact>
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink to="/blog" exact>
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" exact>
              Contact
            </NavLink>
          </li>
        </ul>
        <div className={navToggle === false ? "stack" : "stack toggle"} onClick={toggleNav}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
