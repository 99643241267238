export const projects = [
    {
        id: 1,
        thumbnail: "https://i.postimg.cc/dVw1QxS5/Steam-Deals4by3.png",
        title: "Steam Deals",
        description: "View video game deals on steam",
        technologies: "React, Ruby on Rails, SASS",
        github: "https://github.com/overshootinq/Steam-Deals-Front-End",
        demo: "https://www.youtube.com/watch?v=6Yu1PdeqRls",
    },
    {
        id: 2,
        thumbnail: "https://i.postimg.cc/sDZfJRb6/Silly-Goose4by3.png",
        title: "Silly Goose",
        description: "Stupid jokes for silly people",
        technologies: "React",
        github: "https://github.com/overshootinq/jokester",
        demo: "https://www.youtube.com/watch?v=bcZa_I5yx7A",
    },
    {
        id: 3,
        thumbnail: "https://i.postimg.cc/3Rv7FXcy/Podcast-App4by3.png",
        title: "Podcast App",
        description: "Web app to view podcasts",
        technologies: "React, Ruby on Rails, SASS",
        github: "https://github.com/overshootinq/podcast-app",
        demo: "https://www.youtube.com/watch?v=EsiAueU1w6U",
    },
]