import "./Home.sass";
import { Helmet } from "react-helmet"
import profileImage from "../assets/images/profile-picture.jpg";
import profileBackgroundImage from "../assets/images/pexels-elijah-o'donnell-3473569.jpg";
import documentIcon from "../assets/icons/document_icon.svg";

function Home() {
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Brennan's Webpage</title>
      </Helmet>
      <div className="home-container">
        <div className="home-profile-container">
          <img
            className="home-profile-background-image"
            src={profileBackgroundImage}
            alt="profile background"
          />
          <div className="home-profile-image-container">
            <img
              className="home-profile-image"
              src={profileImage}
              alt="profile shot"
            />
          </div>
          <div className="home-profile-about-container">
            <h1>Brennan McCandliss</h1>
            <p className="home-profile-about-subtext">Web Developer</p>
          </div>
        </div>
        <div className="home-information-container">
            <div className="home-content-pod">
              <h3 className="home-content-pod-title">About</h3>
              <p className="home-content-pod-text">
                Welcome to my website. I'm Brennan, and I'm a web developer based
                in Northern California. This website hosts projects I've been
                working on, my blog, and things I think are interesting.
              </p>
            </div>
            <div className="home-content-pod">
              <h3 className="home-content-pod-title">Skills</h3>
              <p className="home-content-pod-text">
                React, Javascript, Ruby on Rails, Ruby, WordPress, HTML, CSS, SASS, Git,
                Linux
              </p>
            </div>
            <div className="home-content-pod">
              <h3 className="home-content-pod-title">Resume</h3>
              <div className="home-resume-item">
                <img
                  className="home-resume-item-icon"
                  src={documentIcon}
                  alt="Document"
                />
                <div className="home-content-pod-text home-resume-item-description">
                  <p>Brennan's Resume</p>
                  <p>
                    <a href="https://docs.google.com/document/d/1IcpPpFjSrurwLw7gPqMrpCoaVI0q9pgFQEmsksZM0aI/edit?usp=sharing" target="_blank" rel="noreferrer">
                      <button className="home-resume-item-button">View</button>
                    </a>
                  </p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
