import "./ProjectCard.sass";
import {projects} from "../ProjectData"

function ProjectCard() {
    const projectCard = projects.map((project) => (
        <div className="project-card" key={project.id}>
          <img
            className="project-card-thumbnail"
            src={project.thumbnail}
            alt="project thumbnail"
          />
          <div className="project-card-information-container">
            <h3 className="project-card-title">{project.title}</h3>
            <p className="project-card-description">{project.description}</p>
            <p className="project-card-technologies">{project.technologies}</p>
            <div className="project-card-links">
              <a className="project-card-github" href={project.github} target="_blank" rel="noreferrer">Github</a>|
              <a className="project-card-demo" href={project.demo} target="_blank" rel="noreferrer">Demo</a>
            </div>
          </div>
        </div>
      ));

    return (
        <div className="project-card-container">
            {projectCard}
        </div>
    );
}

export default ProjectCard;
