import "./Contact.sass";
import { Helmet } from "react-helmet"
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContactForm from "../components/ContactForm";

function Contact() {
  const handleClick = () => {
    alert("Email copied to clipboard");
  };

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Contact me</title>
      </Helmet>
      <div className="contact-container">
        <div className="contact-information-container">
          <h2 className="contact-information-header">Contact</h2>
          <p className="contact-information-body">
            If you need to contact me for tech support, use GitHub. If you find a
            small bug or typo feel free to email me.
          </p>
          <hr className="contact-information-line" />
          <div className="contact-information-list">
            <ul>
              <li>
                <CopyToClipboard text="osmedia1220@gmail.com">
                  <img src="https://cdn.jsdelivr.net/npm/simple-icons@3.13.0/icons/gmail.svg" alt="Email" onClick={handleClick}/>
                </CopyToClipboard>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/brennanmccandliss/" target="_blank" rel="noreferrer">
                  <img src="https://cdn.jsdelivr.net/npm/simple-icons@v3/icons/linkedin.svg" alt="LinkedIn"/>
                </a>
              </li>
              <li>
                <a href="http://github.com/overshootinq" target="_blank" rel="noreferrer">
                  <img alt="GitHub" src="https://cdn.jsdelivr.net/npm/simple-icons@v3/icons/github.svg"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Contact;
