import "./Blog.sass"
import { Helmet } from "react-helmet"

function Blog() {
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Brennan's Blog</title>
      </Helmet>
      <div className="blog-container">
        <h2 className="blog-header">Brennan's Blog Index</h2>
        <hr className="blog-line"/>
        <div className="blog-list">
        </div>
      </div>
    </div>
  );
}

export default Blog;