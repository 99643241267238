import "./ContactForm.sass";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function ContactForm() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_mhmrl59",
        "template_ntxy6a1",
        form.current,
        "hlropmxY85vfvVHn2"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent")
        },
        (error) => {
          console.log(error.text);
          alert("Error, please try again later, or reach out directly to osmedia1220@gmail.com")
        }
      );
  };

  return (
    <div className="contact-form">
      <form ref={form} onSubmit={sendEmail}>
        <h3 className="contact-form-header">Connect with me</h3>
        <input className="contact-form-input" type="text" name="user_name" placeholder="Name"/>
        <input className="contact-form-input" type="email" name="user_email" placeholder="Email"/>
        <textarea className="contact-form-message" name="message" placeholder="Message"/>
        <button className="contact-form-submit" value="Send" type="submit">Send</button>
      </form>
    </div>
  );
}

export default ContactForm;
