import "./Projects.sass";
import { Helmet } from "react-helmet";
import ProjectCard from "../components/ProjectCard";

function Projects() {
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Brennan's Projects</title>
      </Helmet>
      <div className="projects-container">
        <h2 className="projects-container-header">Projects</h2>
        <p className="projects-body">My projects</p>
        <hr className="projects-line" />
        <ProjectCard />
      </div>
    </div>
  );
}

export default Projects;
